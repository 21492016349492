import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import store from './store/Store'
import router from './router/Router'
import './assets/scss/style.scss'
import App from './App.vue'
import VueFeather from 'vue-feather'
import InstantSearch from 'vue-instantsearch'

import JsonViewer from 'vue-json-viewer'
// import 'vue-json-viewer/style.css'
Vue.use(JsonViewer)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VueHotkey from 'v-hotkey'
Vue.use(VueHotkey)

// i18n
// import i18n from './i18n/i18n.js'
import 'vue-hljs/dist/style.css'
Vue.use(VueFeather)

Vue.config.productionTip = false

import vueHljs from 'vue-hljs'
import hljs from 'highlight.js'

//use
Vue.use(vueHljs, { hljs })
Vue.use(InstantSearch)

const instance = ({ store, router /*, i18n */ }) => {
    return new Vue({
        store,
        router,
        // i18n,
        render: (h) => h(App)
    })
}

store
    .dispatch('init')
    .then(() => {
        instance({ store, router /*, i18n */ }).$mount('#app')
    })
    .catch((error) => {
        const { message, mensaje, mensaje_exceptio } = error
        instance({ store, router /*, i18n */ })
            .$mount('#app')
            .$bvModal.msgBoxOk(
                message ||
                    mensaje ||
                    mensaje_exceptio ||
                    'Ha ocurrido un error!',
                {
                    title: 'Error!',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'error',
                    okTitle: 'Aceptar',
                    cancelTitle: 'Cancelar',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    headerBgVariant: 'error',
                    headerClass: 'modal-header'
                }
            )
            .then(() => {
                // console.log(value);
            })
            .catch((err) => {
                console.log(err)
            })
    })
