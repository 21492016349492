<template>
    <div id="main-wrapper">
        <notifications
            group="general"
            position="bottom right"
            classes="notifications-style"
        />
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.truncate-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Buscar';
}

.custom-file-input {
    border-color: black;
}*/
</style>
