import { services } from './config'
import { getItem } from '@/utils/localStorage'
import { encrypt } from '@/utils/security'

let methods = {}

const getMethods = (instance) => {
    const navigatorObject = navigator.userAgentData
        ? navigator.userAgentData.toJSON()
        : {}
    const navigatorObjectString = JSON.stringify(navigatorObject)
    const encryptedNavigatorInfo = encrypt(navigatorObjectString)

    const POST = ({ url, data, headers = {}, onUploadProgress }) => {
        const token = getItem('TOKEN')

        return instance({
            method: 'post',
            url,
            data,
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
                'x-validation-erp': encryptedNavigatorInfo
            },
            onUploadProgress
        })
    }

    const GET = ({ url, headers = {} }) => {
        const token = getItem('TOKEN')

        return instance({
            method: 'get',
            url,
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
                'x-validation-erp': encryptedNavigatorInfo
            }
        })
    }

    const PATCH = ({ url, data, headers = {}, onUploadProgress }) => {
        const token = getItem('TOKEN')

        return instance({
            method: 'patch',
            url,
            data,
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
                'x-validation-erp': encryptedNavigatorInfo
            },
            onUploadProgress
        })
    }

    const DELETE = ({ url, data, headers = {} }) => {
        const token = getItem('TOKEN')

        return instance({
            method: 'delete',
            url,
            data,
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
                'x-validation-erp': encryptedNavigatorInfo
            }
        })
    }

    return { POST, GET, PATCH, DELETE }
}

for (const key in services) {
    const service = services[key]
    const instance_methods = getMethods(service)
    methods = { ...methods, [key]: instance_methods }
}

export default methods
