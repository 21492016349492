const mutations = {
  setSedes: (state, sedes) => {
    state.sedes = sedes
  },
  setUltimaSede: (state, sede) => {
    state.ultimaSede = sede
  }
}

export default mutations
