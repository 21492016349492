import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import { getItem } from '@/utils/localStorage'
import usuario from './modules/usuario'
import empresas from './modules/empresas'
import sedes from './modules/sedes'
import services from '@/boot/axios'
import { getFileMetaData } from '@/utils/others'
import router, { routes } from '@/router/Router'
// import instance from '../main'

const { API } = services

Vue.use(Vuex)

export const assignRoutes = (menu, clearMenu = false) => {
    const filteredMenu = menu.filter((i) => !i.header)
    const mainRoute = routes.find((r) => r.path === '/')
    let includedRoutes = []

    if (clearMenu) {
        const main = router.options.routes.find((r) => r.path === '/')
        main.children.splice(0, main.children.length)
    }

    filteredMenu.forEach((item) => {
        item.children?.forEach((child) => {
            includedRoutes = mainRoute.children.filter((r) => {
                return r.path.includes(child.name)
            })

            includedRoutes.forEach((r) => {
                router.addRoute('root', r)
            })
        })
    })
}

export default new Vuex.Store({
    modules: {
        usuario,
        empresas,
        sedes
    },
    state: {
        Theme: getItem('THEME') || 'light', // theme can be light or dark
        LayoutType: getItem('LAYOUT_TYPE') || 'full-sidebar', // this can be full-sidebar, mini-sidebar
        Sidebar_drawer: false,
        Customizer_drawer: false,
        SidebarColor: 'blue-primary', //Change sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
        navbarColor: 'light-blue', //Change navbar Color || 'white', | "gradient-info" | "gradient-success" | "gradient-primary" | "gradient-danger" | "gradient-dark"
        logoColor: 'white', //Change Logo Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
        setHorizontalLayout: getItem('LAYOUT') === 'true', // Horizontal layout
        Direction: 'ltr', // possible value  rtl / ltr
        // Calendar Events
        calendarEvents: [
            {
                title: 'test',
                allDay: true,
                start: moment(),
                end: moment().add(1, 'd')
            }
        ],
        queryFilters: []
    },
    mutations: {
        SET_THEME(state, payload) {
            state.Theme = payload
        },
        SET_LAYOUT_TYPE(state, payload) {
            state.LayoutType = payload
        },
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload
        },
        SET_NAVBAR_COLOR(state, payload) {
            state.navbarColor = payload
        },
        SET_LOGO_COLOR(state, payload) {
            state.logoColor = payload
        },
        SET_LAYOUT(state, payload) {
            state.setHorizontalLayout = payload
        },
        SET_DIRECTION(state, payload) {
            state.Direction = payload
        },
        // Add Calendar event
        ADD_CALENDAR_EVENT(state, event) {
            const newEvent = Object.assign({}, event)
            state.calendarEvents.push(newEvent)
        },
        setQueryFilter(state, { query, path }) {
            const retrieveQuery = state.queryFilters.find(
                (q) => q.path === path
            )

            if (query) {
                const index = state.queryFilters.indexOf(retrieveQuery)

                state.queryFilters.splice(index, 1, { ...query, path })
            } else {
                state.queryFilters.push({ ...query, path })
            }
        },
        removeQueryFilter(state, path) {
            const query = state.queryFilters.find(
                (query) => query.path === path
            )

            if (query) {
                const index = state.queryFilters.indexOf(query)

                state.queryFilters.splice(index, 1)
            }
        }
    },
    actions: {
        setLayoutType({ commit }, width) {
            commit('SET_LAYOUT_TYPE', width)
        },
        //Calendar Action
        addCalendarEvent({ commit }, event) {
            commit('ADD_CALENDAR_EVENT', event)
        },
        init({ commit, getters }) {
            return new Promise((resolve, reject) => {
                API.POST({
                    url: 'user/query',
                    data: {
                        p_datajson: {
                            usuario_id: getters['usuarios/getUserData']?.id
                        },
                        p_opc: 'FIND_BY_ID'
                        // p_auditoriajson: { usuario_id: this.getUserData.id }
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception },
                                dato: userInfo
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(userInfo.ultima_sede)
                            const ultimaSede = userInfo.ultima_sede
                                ? Object.keys(userInfo.ultima_sede).length > 0
                                    ? JSON.parse(
                                          JSON.stringify(userInfo.ultima_sede)
                                      )
                                    : userInfo.sedes[0]
                                : null

                            const ultimaEmpresa = userInfo.ultima_empresa
                                ? Object.keys(userInfo.ultima_empresa).length >
                                  0
                                    ? JSON.parse(
                                          JSON.stringify(
                                              userInfo.ultima_empresa
                                          )
                                      )
                                    : userInfo.empresas.find(
                                          (e) => e.id === ultimaSede.empresa_id
                                      )
                                : null

                            const roles = JSON.parse(
                                JSON.stringify(userInfo.roles)
                            )

                            let role = {}
                            if (roles.length === 1 && roles[0].sede_id === 0) {
                                role = roles[0].role
                            } else {
                                const retrieveRole = roles.find(
                                    (r) => r.sede_id === ultimaSede.id
                                )
                                role = retrieveRole.role
                            }

                            commit('usuario/setUserData', userInfo, {
                                root: true
                            })
                            commit('usuario/setMenu', role.menus, {
                                root: true
                            })
                            commit(
                                'empresas/setEmpresas',
                                userInfo.empresas || [],
                                {
                                    root: true
                                }
                            )
                            commit('sedes/setSedes', userInfo.sedes || [], {
                                root: true
                            })
                            commit('empresas/setUltimaEmpresa', ultimaEmpresa, {
                                root: true
                            })
                            commit('sedes/setUltimaSede', ultimaSede, {
                                root: true
                            })
                            assignRoutes(getters['usuario/getMenu'])
                            setTimeout(() => {
                                resolve()
                            }, 1000)
                        } else {
                            reject({ mensaje, mensaje_exception })
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        getImportFormat({ getters }, modulo) {
            // const usuario_id = getters['usuario/getUserData'].id
            const getAuditoriaJson = getters['usuario/getAuditoriaJson']
            const auditoriajson = { ...getAuditoriaJson, modulo_id: 36 }
            API.POST({
                url: 'report/form/export',
                data: {
                    p_datajson: { modulo },
                    p_opc: 'EXPORT',
                    p_auditoriajson: auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        const { ext, fileData } = getFileMetaData(dato)
                        var a = document.createElement('a')
                        a.href = fileData
                        a.download = `formato-${modulo}-importacion.${ext}`
                        a.click()
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        }
    },
    getters: {
        getQueryFilter(state) {
            return function (path) {
                return state.queryFilters.find((query) => query.path === path)
            }
        }
    }
})
