const mutations = {
  setEmpresas: (state, empresas) => {
    state.empresas = empresas
  },
  setUltimaEmpresa: (state, empresa) => {
    state.ultimaEmpresa = empresa
  }
}

export default mutations
