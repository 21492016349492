import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/Store'
import { getItem } from '@/utils/localStorage'

Vue.use(Router)

export const routes = [
    {
        path: '/',
        redirect: '/auth/login',
        // /dashboard/modern-dashboard'
        name: 'root',
        component: () => import('@/layouts/full-layout/FullLayout'),
        children: [
            // dashboard
            {
                name: 'Modern Dashboard',
                path: '/home/dashboard',
                meta: {
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/dashboards/modern-dashboard/ModernDashboard'
                    )
            },
            {
                name: 'Empresas',
                path: '/home/general/generales/empresas',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/generales/empresas/main.empresas')
            },
            {
                name: 'Crear Empresa',
                path: '/home/general/generales/empresas/crear-empresa',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/general/generales/empresas/crearEmpresa.vue'
                    )
            },
            {
                name: 'Editar Empresa',
                path: '/home/general/generales/empresas/editar-empresa/:empresa_id',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/general/generales/empresas/crearEmpresa.vue'
                    )
            },
            {
                name: 'Ver Empresa',
                path: '/home/general/generales/empresas/ver-empresa/:empresa_id',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/general/generales/empresas/crearEmpresa.vue'
                    )
            },
            {
                name: 'Sedes',
                path: '/home/general/generales/sedes',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/generales/sedes/main.sedes')
            },
            {
                name: 'Crear Sede',
                path: '/home/general/generales/sedes/crear-sede',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/generales/sedes/crearSede.vue')
            },
            {
                name: 'Editar Sede',
                path: '/home/general/generales/sedes/editar-sede/:sede_id',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/generales/sedes/crearSede.vue')
            },
            {
                name: 'Ver Sede',
                path: '/home/general/generales/sedes/ver-sede/:sede_id',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import('@/views/general/generales/sedes/crearSede.vue')
            },
            {
                name: 'Terceros',
                path: '/home/general/generales/terceros',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/generales/terceros/main.terceros')
            },
            {
                name: 'Crear Tercero',
                path: '/home/general/generales/terceros/crear-tercero',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/generales/terceros/crearTercero')
            },
            {
                name: 'Editar Tercero',
                path: '/home/general/generales/terceros/editar-tercero/:tercero_id',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/generales/terceros/crearTercero')
            },
            {
                name: 'Ver Tercero',
                path: '/home/general/generales/terceros/ver-tercero/:tercero_id',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import('@/views/general/generales/terceros/crearTercero')
            },
            {
                name: 'Configuración email',
                path: '/home/general/generales/configuracion-email',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/general/generales/configuracion-email/main.configuracion-email'
                    )
            },
            {
                name: 'Crear configuración emial',
                path: '/home/general/generales/configuracion-email/crear-configuracion-email',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/general/generales/configuracion-email/crearConfiguracionEmail'
                    )
            },
            {
                name: 'Editar configuración emial',
                path: '/home/general/generales/configuracion-email/editar-configuracion-email/:configuracion_email_id',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/general/generales/configuracion-email/crearConfiguracionEmail'
                    )
            },
            {
                name: 'Ver configuración emial',
                path: '/home/general/generales/configuracion-email/ver-configuracion-email/:configuracion_email_id',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/general/generales/configuracion-email/crearConfiguracionEmail'
                    )
            },
            //auditoría
            {
                name: 'Auditoría',
                path: '/home/general/seguridad/auditoria',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/seguridad/auditoria/main.auditoria')
            },
            {
                name: 'Ver auditoría',
                path: '/home/general/seguridad/auditoria/ver-auditoria/:auditoria_id',
                meta: {
                    module: 'Gneral',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/seguridad/auditoria/ver.auditoria')
            },
            {
                name: 'Usuarios',
                path: '/home/general/seguridad/usuarios',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/seguridad/usuarios/main.usuarios')
            },
            {
                name: 'Crear usuario',
                path: '/home/general/seguridad/usuarios/crear-usuario',
                meta: {
                    module: 'Gneral',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/seguridad/usuarios/crear.usuario')
            },
            {
                name: 'Editar usuario',
                path: '/home/general/seguridad/usuarios/editar-usuario/:usuario_id',
                meta: {
                    module: 'Gneral',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/seguridad/usuarios/crear.usuario')
            },
            {
                name: 'Ver usuario',
                path: '/home/general/seguridad/usuarios/ver-usuario/:usuario_id',
                meta: {
                    module: 'Gneral',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import('@/views/general/seguridad/usuarios/crear.usuario')
            },
            // roles
            {
                name: 'Roles',
                path: '/home/general/seguridad/roles',
                meta: {
                    module: 'General',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/seguridad/roles/main.roles')
            },
            {
                name: 'Crear rol',
                path: '/home/general/seguridad/roles/crear-rol',
                meta: {
                    module: 'Gneral',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/seguridad/roles/crear.rol')
            },
            {
                name: 'Editar rol',
                path: '/home/general/seguridad/roles/editar-rol/:rol_id',
                meta: {
                    module: 'Gneral',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/general/seguridad/roles/crear.rol')
            },
            {
                name: 'Ver rol',
                path: '/home/general/seguridad/roles/ver-rol/:rol_id',
                meta: {
                    module: 'Gneral',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import('@/views/general/seguridad/roles/crear.rol')
            },
            {
                name: 'Perfil',
                path: '/home/general/seguridad/perfil',
                meta: {
                    module: 'General',
                    requiresAuth: true
                },
                component: () =>
                    import('@/views/general/configuracion/perfil/main.perfil')
            },
            // zonas
            {
                name: 'Zonas',
                path: '/home/administrativo/configuracion/zonas',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/zonas/main.zonas'
                    )
            },
            {
                name: 'Crear Zona',
                path: '/home/administrativo/configuracion/zonas/crear-zona',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/zonas/crearZonas'
                    )
            },
            {
                name: 'Editar Zona',
                path: '/home/administrativo/configuracion/zonas/editar-zona/:zona_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/zonas/crearZonas'
                    )
            },
            {
                name: 'Ver Zona',
                path: '/home/administrativo/configuracion/zonas/ver-zona/:zona_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/zonas/crearZonas'
                    )
            },
            {
                name: 'Vendedores',
                path: '/home/administrativo/configuracion/vendedores',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/vendedores/main.vendedores'
                    )
            },
            {
                name: 'Crear Vendedor',
                path: '/home/administrativo/configuracion/vendedores/crear-vendedor',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/vendedores/crearVendedores'
                    )
            },
            {
                name: 'Editar Vendedor',
                path: '/home/administrativo/configuracion/vendedores/editar-vendedor/:vendedor_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/vendedores/crearVendedores'
                    )
            },
            {
                name: 'Ver Vendedor',
                path: '/home/administrativo/configuracion/vendedores/ver-vendedor/:vendedor_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/vendedores/crearVendedores'
                    )
            },
            {
                name: 'Establecimientos de comercio',
                path: '/home/administrativo/configuracion/establecimientos-comercio',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/establecimientos-comercio/main.establecimientos-comercio'
                    )
            },
            {
                name: 'Crear establecimiento comercio',
                path: '/home/administrativo/configuracion/establecimientos-comercio/crear-establecimiento-comercio',
                props: (route) => ({
                    ...route.params
                }),
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/establecimientos-comercio/crearEstablecimientoComercio'
                    )
            },
            {
                name: 'Editar establecimiento comercio',
                path: '/home/administrativo/configuracion/establecimientos-comercio/editar-establecimiento-comercio/:establecimiento_comercio_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/establecimientos-comercio/crearEstablecimientoComercio'
                    )
            },
            {
                name: 'Ver establecimiento comercio',
                path: '/home/administrativo/configuracion/establecimientos-comercio/ver-establecimiento-comercio/:establecimiento_comercio_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/establecimientos-comercio/crearEstablecimientoComercio'
                    )
            },
            // formas de pago
            {
                name: 'Formas de pago',
                path: '/home/administrativo/configuracion/formas-pago',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/formas-pago/main.formas-pago'
                    )
            },
            {
                name: 'Crear formas de pago',
                path: '/home/administrativo/configuracion/formas-pago/crear-forma-pago',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/formas-pago/crearFormasPago'
                    )
            },
            {
                name: 'Editar formas de pago',
                path: '/home/administrativo/configuracion/formas-pago/editar-forma-pago/:forma_pago_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/formas-pago/crearFormasPago'
                    )
            },
            {
                name: 'Ver formas de pago',
                path: '/home/administrativo/configuracion/formas-pago/ver-forma-pago/:forma_pago_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/formas-pago/crearFormasPago'
                    )
            },
            // conceptos de digitación
            {
                name: 'Conceptos de digitación',
                path: '/home/administrativo/configuracion/conceptos-digitacion',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/concepto-digitacion/main.concepto-digitacion'
                    )
            },
            {
                name: 'Crear concepto de digitación',
                path: '/home/administrativo/configuracion/conceptos-digitacion/crear-concepto-digitacion',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/concepto-digitacion/crearConceptoDigitacion'
                    )
            },
            {
                name: 'Editar concepto de digitación',
                path: '/home/administrativo/configuracion/conceptos-digitacion/editar-concepto-digitacion/:concepto_digitacion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/concepto-digitacion/crearConceptoDigitacion'
                    )
            },
            {
                name: 'Ver concepto de digitación',
                path: '/home/administrativo/configuracion/conceptos-digitacion/ver-concepto-digitacion/:concepto_digitacion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/concepto-digitacion/crearConceptoDigitacion'
                    )
            },
            // Conceptos contables
            {
                name: 'Conceptos contables',
                path: '/home/administrativo/configuracion/conceptos-contables',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/concepto-contable/main.concepto-contable'
                    )
            },
            {
                name: 'Crear concepto contable',
                path: '/home/administrativo/configuracion/conceptos-contables/crear-concepto-contable',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/concepto-contable/crearConceptoContable'
                    )
            },
            {
                name: 'Editar concepto contable',
                path: '/home/administrativo/configuracion/conceptos-contables/editar-concepto-contable/:concepto_contable_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/concepto-contable/crearConceptoContable'
                    )
            },
            {
                name: 'Ver concepto contable',
                path: '/home/administrativo/configuracion/conceptos-contables/ver-concepto-contable/:concepto_contable_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/concepto-contable/crearConceptoContable'
                    )
            },
            // cuentas bancarias
            {
                name: 'Cuentas bancarias',
                path: '/home/administrativo/configuracion/cuentas-bancarias',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/cuentas-bancarias/main.cuentas-bancarias'
                    )
            },
            {
                name: 'Crear cuenta bancaria',
                path: '/home/administrativo/configuracion/cuentas-bancarias/crear-cuenta-bancaria',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/cuentas-bancarias/crearCuentaBancaria'
                    )
            },
            {
                name: 'Editar cuenta bancaria',
                path: '/home/administrativo/configuracion/cuentas-bancarias/editar-cuenta-bancaria/:cuenta_bancaria_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/cuentas-bancarias/crearCuentaBancaria'
                    )
            },
            {
                name: 'Ver cuenta bancaria',
                path: '/home/administrativo/configuracion/cuentas-bancarias/ver-cuenta-bancaria/:cuenta_bancaria_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/cuentas-bancarias/crearCuentaBancaria'
                    )
            },
            // consecutivos
            {
                name: 'Consecutivos',
                path: '/home/administrativo/configuracion/consecutivos',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/consecutivos/main.consecutivos'
                    )
            },
            {
                name: 'Crear consecutivo',
                path: '/home/administrativo/configuracion/consecutivos/crear-consecutivo',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/consecutivos/crearConsecutivo'
                    )
            },
            {
                name: 'Editar consecutivo',
                path: '/home/administrativo/configuracion/consecutivos/editar-consecutivo/:consecutivo_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/consecutivos/crearConsecutivo'
                    )
            },
            {
                name: 'Ver consecutivo',
                path: '/home/administrativo/configuracion/consecutivos/ver-consecutivo/:consecutivo_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/consecutivos/crearConsecutivo'
                    )
            },
            // tipos devoluciones
            {
                name: 'Tipos de devoluciones',
                path: '/home/administrativo/configuracion/tipo-devolucion',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/tipos-devoluciones/main.tiposdevolucion'
                    )
            },
            {
                name: 'Crear tipo de devolución',
                path: '/home/administrativo/configuracion/tipo-devolucion/crear-tipo-devolucion',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/tipos-devoluciones/crearTipoDevolucion'
                    )
            },
            {
                name: 'Editar tipo de devolución',
                path: '/home/administrativo/configuracion/tipo-devolucion/editar-tipo-devolucion/:tipo_devolucion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/tipos-devoluciones/crearTipoDevolucion'
                    )
            },
            {
                name: 'Ver tipo de devolución',
                path: '/home/administrativo/configuracion/tipo-devolucion/ver-tipo-devolucion/:tipo_devolucion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/tipos-devoluciones/crearTipoDevolucion'
                    )
            },
            // iva
            {
                name: 'Tarifas de Iva',
                path: '/home/administrativo/inventario/tarifa-iva',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/tarifa-iva/main.tarifa-iva'
                    )
            },
            {
                name: 'Crear tarifa iva',
                path: '/home/administrativo/inventario/tarifa-iva/crear-tarifa-iva',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/tarifa-iva/crearTarifaIva'
                    )
            },
            {
                name: 'Editar tarifa iva',
                path: '/home/administrativo/inventario/tarifa-iva/editar-tarifa-iva/:iva_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/tarifa-iva/crearTarifaIva'
                    )
            },
            {
                name: 'Ver tarifa iva',
                path: '/home/administrativo/inventario/tarifa-iva/ver-tarifa-iva/:iva_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/tarifa-iva/crearTarifaIva'
                    )
            },
            // retencion
            {
                name: 'Retefuente',
                path: '/home/administrativo/inventario/retefuente',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/retefuente/main.retefuente'
                    )
            },
            {
                name: 'Crear retefuente',
                path: '/home/administrativo/inventario/retefuente/crear-retefuente',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/retefuente/crearRetefuente'
                    )
            },
            {
                name: 'Editar retefuente',
                path: '/home/administrativo/inventario/retefuente/editar-retefuente/:retencion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/retefuente/crearRetefuente'
                    )
            },
            {
                name: 'Ver retefuente',
                path: '/home/administrativo/inventario/retefuente/ver-retefuente/:retencion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/retefuente/crearRetefuente'
                    )
            },
            // autoretención - [retecree]
            {
                name: 'Autoretención',
                path: '/home/administrativo/inventario/autoretencion',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/autoretencion/main.autoretencion'
                    )
            },
            {
                name: 'Crear autoretención',
                path: '/home/administrativo/inventario/autoretencion/crear-autoretencion',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/autoretencion/crearAutoretencion'
                    )
            },
            {
                name: 'Editar autoretención',
                path: '/home/administrativo/inventario/autoretencion/editar-autoretencion/:autoretencion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/autoretencion/crearAutoretencion'
                    )
            },
            {
                name: 'Ver autoretención',
                path: '/home/administrativo/inventario/autoretencion/ver-autoretencion/:autoretencion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/autoretencion/crearAutoretencion'
                    )
            },
            // categoria
            {
                name: 'Categoría',
                path: '/home/administrativo/inventario/categoria',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/categoria/main.categoria'
                    )
            },
            {
                name: 'Crear categoría',
                path: '/home/administrativo/inventario/categoria/crear-categoria',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/categoria/crearCategoria'
                    )
            },
            {
                name: 'Editar categoría',
                path: '/home/administrativo/inventario/categoria/editar-categoria/:categoria_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/categoria/crearCategoria'
                    )
            },
            {
                name: 'Ver categoría',
                path: '/home/administrativo/inventario/categoria/ver-categoria/:categoria_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/categoria/crearCategoria'
                    )
            },
            // categoria
            {
                name: 'Subcategoría',
                path: '/home/administrativo/inventario/subcategoria',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/subcategoria/main.subcategoria'
                    )
            },
            {
                name: 'Crear subcategoría',
                path: '/home/administrativo/inventario/subcategoria/crear-subcategoria',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/subcategoria/crearSubcategoria'
                    )
            },
            {
                name: 'Editar subcategoría',
                path: '/home/administrativo/inventario/subcategoria/editar-subcategoria/:subcategoria_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/subcategoria/crearSubcategoria'
                    )
            },
            {
                name: 'Ver subcategoría',
                path: '/home/administrativo/inventario/subcategoria/ver-subcategoria/:subcategoria_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/subcategoria/crearSubcategoria'
                    )
            },
            // unidad contenido
            {
                name: 'Unidad contenido',
                path: '/home/administrativo/inventario/unidad-contenido',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/unidad-contenido/main.unidadContenido'
                    )
            },
            {
                name: 'Crear unidad contenido',
                path: '/home/administrativo/inventario/unidad-contenido/crear-unidad-contenido',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/unidad-contenido/crearUnidadContenido'
                    )
            },
            {
                name: 'Editar unidad contenido',
                path: '/home/administrativo/inventario/unidad-contenido/editar-unidad-contenido/:unidad_contenido_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/unidad-contenido/crearUnidadContenido'
                    )
            },
            {
                name: 'Ver unidad contenido',
                path: '/home/administrativo/inventario/unidad-contenido/ver-unidad-contenido/:unidad_contenido_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/unidad-contenido/crearUnidadContenido'
                    )
            },
            // unidad embalaje
            {
                name: 'Unidad embalaje',
                path: '/home/administrativo/inventario/unidad-embalaje',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/unidad-embalaje/main.unidadEmbalaje'
                    )
            },
            {
                name: 'Crear unidad embalaje',
                path: '/home/administrativo/inventario/unidad-embalaje/crear-unidad-embalaje',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/unidad-embalaje/crearUnidadEmbalaje'
                    )
            },
            {
                name: 'Editar unidad embalaje',
                path: '/home/administrativo/inventario/unidad-embalaje/editar-unidad-embalaje/:unidad_embalaje_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/unidad-embalaje/crearUnidadEmbalaje'
                    )
            },
            {
                name: 'Ver unidad embalaje',
                path: '/home/administrativo/inventario/unidad-embalaje/ver-unidad-embalaje/:unidad_embalaje_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/unidad-embalaje/crearUnidadEmbalaje'
                    )
            },
            // items
            {
                name: 'Items',
                path: '/home/administrativo/inventario/items',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/administrativo/inventario/items/main.item')
            },
            {
                name: 'Crear items',
                path: '/home/administrativo/inventario/items/crear-item',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/administrativo/inventario/items/crearItem')
            },
            {
                name: 'Editar item',
                path: '/home/administrativo/inventario/items/crear-item/:item_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/administrativo/inventario/items/crearItem')
            },
            {
                name: 'Ver item',
                path: '/home/administrativo/inventario/items/ver-item/:item_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import('@/views/administrativo/inventario/items/crearItem')
            },
            // Bodegas
            {
                name: 'Bodegas',
                path: '/home/administrativo/inventario/bodegas',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/bodegas/main.bodega'
                    )
            },
            {
                name: 'Crear bodegas',
                path: '/home/administrativo/inventario/bodegas/crear-bodega',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/bodegas/crearBodega'
                    )
            },
            {
                name: 'Editar bodega',
                path: '/home/administrativo/inventario/bodegas/editar-bodega/:bodega_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/bodegas/crearBodega'
                    )
            },
            {
                name: 'Ver bodega',
                path: '/home/administrativo/inventario/bodegas/ver-bodega/:bodega_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/bodegas/crearBodega'
                    )
            },
            // lotes de inventario
            {
                name: 'Lotes de inventario',
                path: '/home/administrativo/inventario/lotes-inventario',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/lotes-inventario/main.lotes-inventario'
                    )
            },
            {
                name: 'Crear lote inventario',
                path: '/home/administrativo/inventario/lotes-inventario/crear-lote-inventario',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/lotes-inventario/crearLoteInventario'
                    )
            },
            {
                name: 'Editar lote inventario',
                path: '/home/administrativo/inventario/lotes-inventario/editar-lote-inventario/:lote_inventario_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/lotes-inventario/crearLoteInventario'
                    )
            },
            {
                name: 'Ver lote inventario',
                path: '/home/administrativo/inventario/lotes-inventario/editar-lote-inventario/:lote_inventario_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/lotes-inventario/crearLoteInventario'
                    )
            },
            // Movimientos de inventario
            {
                name: 'Movimientos de inventario',
                path: '/home/administrativo/inventario/movimientos-inventario',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/movimientos-inventario/main.movimientos-inventario'
                    )
            },
            {
                name: 'Ver movimiento de inventario',
                path: '/home/administrativo/inventario/movimientos-inventario/ver-movimiento/:movimiento_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/inventario/movimientos-inventario/movimientoInventarioView'
                    )
            },
            // Puc
            {
                name: 'Puc',
                path: '/home/contabilidad/configuracion/puc',
                meta: {
                    module: 'Contabilidad',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/contabilidad/configuracion/puc/main.puc')
            },
            {
                name: 'Crear item del puc',
                path: '/home/contabilidad/configuracion/puc/crear-item-puc',
                meta: {
                    module: 'Contabilidad',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/contabilidad/configuracion/puc/crearPuc')
            },
            {
                name: 'Editar item del puc',
                path: '/home/contabilidad/configuracion/puc/editar-item-puc/:puc_id',
                meta: {
                    module: 'Contabilidad',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import('@/views/contabilidad/configuracion/puc/crearPuc')
            },
            {
                name: 'Ver item del puc',
                path: '/home/contabilidad/configuracion/puc/ver-item-puc/:puc_id',
                meta: {
                    module: 'Contabilidad',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import('@/views/contabilidad/configuracion/puc/crearPuc')
            },
            // Factura de venta
            {
                name: 'Facturas de venta',
                path: '/home/administrativo/documentos/facturas-venta',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/factura-venta/main.factura-venta'
                    )
            },
            {
                name: 'Crear factura de venta',
                path: '/home/administrativo/documentos/facturas-venta/crear-factura-venta',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/factura-venta/crearFacturaVenta'
                    )
            },
            {
                name: 'Editar Factura de venta',
                path: '/home/administrativo/documentos/facturas-venta/editar-factura-venta/:factura_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/factura-venta/crearFacturaVenta'
                    )
            },
            {
                name: 'Ver Factura de venta',
                path: '/home/administrativo/documentos/facturas-venta/ver-factura-venta/:factura_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/factura-venta/crearFacturaVenta'
                    )
            },
            //devolucion factura
            {
                name: 'Devolucion de facturas',
                path: '/home/administrativo/documentos/devolucion-factura',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/devolucion-factura/main.devolucionesFactura'
                    )
            },
            {
                name: 'Crear devolucion de factura',
                path: '/home/administrativo/documentos/devolucion-factura/crear-devolucion-factura/:factura_venta_id?',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/devolucion-factura/crearDevolucionFactura'
                    )
            },
            {
                name: 'Ver devolucion de factura',
                path: '/home/administrativo/documentos/devolucion-factura/ver-devolucion-factura/:devolucion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/devolucion-factura/crearDevolucionFactura'
                    )
            },
            // Cotización cliente
            {
                name: 'Cotizaciones de cliente',
                path: '/home/administrativo/documentos/cotizaciones-cliente',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/cotizaciones-cliente/main.cotizaciones'
                    )
            },
            {
                name: 'Crear cotización de cliente',
                path: '/home/administrativo/documentos/cotizaciones-cliente/crear-cotizacion',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/cotizaciones-cliente/crearCotizacion'
                    )
            },
            {
                name: 'Ver cotización de cliente',
                path: '/home/administrativo/documentos/cotizaciones-cliente/ver-cotizacion/:cotizacion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/cotizaciones-cliente/crearCotizacion'
                    )
            },
            // Pedidos
            {
                name: 'Pedidos de cliente',
                path: '/home/administrativo/documentos/pedidos-cliente',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/pedidos-cliente/main.pedidos'
                    )
            },
            {
                name: 'Crear pedido de cliente',
                path: '/home/administrativo/documentos/pedidos-cliente/crear-pedido',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/pedidos-cliente/crearPedido'
                    )
            },
            {
                name: 'Ver pedido de cliente',
                path: '/home/administrativo/documentos/pedidos-cliente/ver-pedido/:pedido_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/pedidos-cliente/crearPedido'
                    )
            },
            // Remisiones
            {
                name: 'Remisiones de cliente',
                path: '/home/administrativo/documentos/remisiones-cliente',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/remisiones-cliente/main.remisiones'
                    )
            },
            {
                name: 'Crear remisión de cliente',
                path: '/home/administrativo/documentos/remisiones-cliente/crear-remision',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/remisiones-cliente/crearRemision'
                    )
            },
            {
                name: 'Ver remisión de cliente',
                path: '/home/administrativo/documentos/remisiones-cliente/ver-remision/:remision_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/remisiones-cliente/crearRemision'
                    )
            },
            //devolucion proveedor
            {
                name: 'Devoluciones proveedor',
                path: '/home/administrativo/documentos/devolucion-proveedor',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/devolucion-proveedor/main.devolucionesProveedor'
                    )
            },
            {
                name: 'Crear devolucion proveedor',
                path: '/home/administrativo/documentos/devolucion-proveedor/crear-devolucion-proveedor/:compra_id?',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/devolucion-proveedor/crearDevolucionProveedor'
                    )
            },
            {
                name: 'Ver devolucion proveedor',
                path: '/home/administrativo/documentos/devolucion-proveedor/ver-devolucion-proveedor/:devolucion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/devolucion-proveedor/crearDevolucionProveedor'
                    )
            },
            // Cotización proveedor
            {
                name: 'Cotizaciones proveedor',
                path: '/home/administrativo/documentos/cotizaciones-proveedor',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/cotizaciones-proveedor/main.cotizacionesProveedor'
                    )
            },
            {
                name: 'Crear cotización proveedor',
                path: '/home/administrativo/documentos/cotizaciones-proveedor/crear-cotizacion',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/cotizaciones-proveedor/crearCotizacionProveedor'
                    )
            },
            {
                name: 'Ver cotización proveedor',
                path: '/home/administrativo/documentos/cotizaciones-proveedor/ver-cotizacion/:cotizacion_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/cotizaciones-proveedor/crearCotizacionProveedor'
                    )
            },
            // Pedidos proveedor
            {
                name: 'Pedidos proveedor',
                path: '/home/administrativo/documentos/pedidos-proveedor',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/pedidos-proveedor/main.pedidos'
                    )
            },
            {
                name: 'Crear pedido proveedor',
                path: '/home/administrativo/documentos/pedidos-proveedor/crear-pedido',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/pedidos-proveedor/crearPedido'
                    )
            },
            {
                name: 'Ver pedido proveedor',
                path: '/home/administrativo/documentos/pedidos-proveedor/ver-pedido/:pedido_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/pedidos-proveedor/crearPedido'
                    )
            },
            // Remisiones proveedor
            {
                name: 'Remisiones proveedor',
                path: '/home/administrativo/documentos/remisiones-proveedor',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/remisiones-proveedor/main.remisiones'
                    )
            },
            {
                name: 'Crear remisión proveedor',
                path: '/home/administrativo/documentos/remisiones-proveedor/crear-remision',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/remisiones-proveedor/crearRemision'
                    )
            },
            {
                name: 'Ver remisión proveedor',
                path: '/home/administrativo/documentos/remisiones-proveedor/ver-remision/:remision_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/remisiones-proveedor/crearRemision'
                    )
            },
            // Compras de mercancía
            {
                name: 'Compras de mercancía',
                path: '/home/administrativo/documentos/compras-mercancia',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/compras-mercancia/main.comprasMercancia'
                    )
            },
            {
                name: 'Crear compra mercancía',
                path: '/home/administrativo/documentos/compras-mercancia/crear-compra-mercancia',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/compras-mercancia/crearCompraMercancia'
                    )
            },
            {
                name: 'Ver compra mercancía',
                path: '/home/administrativo/documentos/compras-mercancia/ver-compra-mercancia/:compra_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/documentos/compras-mercancia/crearCompraMercancia'
                    )
            },
            // reportes ---------------
            {
                name: 'Menú informes',
                path: '/home/administrativo/herramientas/menu-informes',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/reportes/main.reportes'
                    )
            },
            {
                name: 'Crear reporte',
                path: '/home/administrativo/herramientas/menu-informes/crear-reporte',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/reportes/crearReporte'
                    )
            },
            {
                name: 'Editar reporte',
                path: '/home/administrativo/herramientas/menu-informes/editar-reporte/:template_id',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/configuracion/reportes/crearReporte'
                    )
            },
            {
                name: 'Saldos de inventario',
                path: '/home/administrativo/saldos/saldos-inventario',
                meta: {
                    module: 'Administrativo',
                    requiresAuth: true,
                    read_only: true
                },
                component: () =>
                    import(
                        '@/views/administrativo/saldos/saldos-inventario/main.saldosInventario'
                    )
            },
            // Electronica
            {
                name: 'Envío de facturación electrónica',
                path: '/home/electronica/configuracion/envio-factura-electronica',
                meta: {
                    module: 'Electrónica',
                    requiresAuth: true
                },
                component: () =>
                    import(
                        '@/views/electronica/configuracion/envio-factura-electronica/envioFacturacion'
                    )
            },
            {
                name: 'Documentos electrónicos',
                path: '/home/electronica/consulta-documentos/documentos-electronicos',
                meta: {
                    module: 'Electrónica',
                    requiresAuth: true
                },
                component: () =>
                    import(
                        '@/views/electronica/documentos/consultar/documentosElectronicosView'
                    )
            },
            {
                name: 'Nuevo acuse',
                path: '/home/electronica/consulta-documentos/documentos-electronicos/nuevo-acuse',
                meta: {
                    module: 'Electrónica',
                    requiresAuth: true
                },
                component: () =>
                    import(
                        '@/views/electronica/documentos/subir-factura/subirFactura'
                    )
            }
        ]
    },
    {
        path: '/auth',
        component: () => import('@/layouts/blank-layout/Blanklayout'),
        children: [
            // {
            //     name: 'BoxedLogin',
            //     path: 'boxedlogin',
            //     meta: {
            //         requiresAuth: false
            //     },
            //     component: () => import('@/views/authentication/BoxedLogin')
            // },
            {
                name: 'login',
                path: 'login',
                meta: {
                    requiresAuth: false,
                    allowed: true
                },
                component: () => import('@/views/authentication/FullLogin')
            },
            {
                name: 'Olvidó su contraseña',
                path: 'olvido-su-contrasena',
                meta: {
                    requiresAuth: false
                },
                component: () =>
                    import('@/views/authentication/olvido-su-contrasena')
            },
            // {
            //     name: 'BoxedRegister',
            //     path: 'boxedregister',
            //     meta: {
            //         requiresAuth: false
            //     },
            //     component: () => import('@/views/authentication/BoxedRegister')
            // },
            // {
            //     name: 'FullRegister',
            //     path: 'fullregister',
            //     meta: {
            //         requiresAuth: false
            //     },
            //     component: () => import('@/views/authentication/FullRegister')
            // },
            {
                name: 'Error',
                path: 'error',
                meta: {
                    requiresAuth: false,
                    allowed: true
                },
                component: () => import('@/views/authentication/Error')
            }
        ]
    },
    {
        path: '/starting',
        component: () => import('@/layouts/full-layout/FullLayout'),
        children: [
            {
                path: 'crear-empresa',
                name: 'Crear Empresa',
                meta: {
                    requiresAuth: true,
                    allowed: false,
                    module: 'Inicio'
                },
                component: () => import('@/views/empresas/crearEmpresa.view')
            },
            {
                path: 'crear-sede',
                name: 'Crear Sede',
                meta: {
                    requiresAuth: true,
                    allowed: false,
                    module: 'Inicio'
                },
                component: () => import('@/views/sedes/crearSedes.vue')
            }
        ]
    },
    {
        path: '*',
        component: () => import('@/views/authentication/Error')
    }
]

export const defaultRoutes = [
    {
        path: '/',
        redirect: '/auth/login',
        // /dashboard/modern-dashboard'
        name: 'root',
        component: () => import('@/layouts/full-layout/FullLayout'),
        children: [
            {
                name: 'Modern Dashboard',
                path: '/home/dashboard',
                meta: {
                    requiresAuth: true,
                    allowed: false
                },
                component: () =>
                    import(
                        '@/views/dashboards/modern-dashboard/ModernDashboard'
                    )
            }
        ]
    },
    {
        path: '/auth',
        component: () => import('@/layouts/blank-layout/Blanklayout'),
        children: [
            {
                name: 'login',
                path: 'login',
                meta: {
                    requiresAuth: false,
                    allowed: true
                },
                component: () => import('@/views/authentication/FullLogin')
            },
            {
                name: 'Olvidó su contraseña',
                path: 'olvido-su-contrasena',
                meta: {
                    requiresAuth: false
                },
                component: () =>
                    import('@/views/authentication/olvido-su-contrasena')
            }
        ]
    },
    {
        path: '/starting',
        component: () => import('@/layouts/full-layout/FullLayout'),
        children: [
            {
                path: 'crear-empresa',
                name: 'Crear Empresa',
                meta: {
                    requiresAuth: true,
                    allowed: false,
                    module: 'Inicio'
                },
                component: () => import('@/views/empresas/crearEmpresa.view')
            },
            {
                path: 'crear-sede',
                name: 'Crear Sede',
                meta: {
                    requiresAuth: true,
                    allowed: false,
                    module: 'Inicio'
                },
                component: () => import('@/views/sedes/crearSedes.vue')
            }
        ]
    },
    {
        path: '*',
        meta: {
            requiresAuth: 'standard'
        },
        component: () => import('@/views/authentication/Error')
    }
]

const router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    // base: '/erp',
    routes: defaultRoutes
})

import NProgress from 'nprogress'

router.beforeResolve((to, from, next) => {
    NProgress.start(800)
    next()
})

router.beforeEach((to, from, next) => {
    const empresas = store.getters['empresas/getEmpresas']
    const sedes = store.getters['sedes/getSedes']
    const ultimaEmpresa =
        store._wrappedGetters['empresas/getUltimaEmpresa'](store) //store.getters['empresas/getUltimaEmpresa']
    const filteredSedes = sedes.filter((s) => s.empresa_id === ultimaEmpresa.id)
    const autenticado = getItem('TOKEN')
    if (!autenticado && to.meta.requiresAuth === true) {
        // console.log('b 1', to.fullPath)
        next({ path: '/auth/login' })
    } else if (autenticado && to.meta.requiresAuth === false) {
        // console.log('b 2', to.fullPath)
        next({ path: '/home/dashboard/' })
        // router.back()
        // next()
    } else if (to.meta.requiresAuth === 'standard') {
        // console.log('bbbbb')
        next()
    } else {
        if (
            empresas.length === 0 &&
            autenticado &&
            to.fullPath !== '/starting/crear-empresa'
        ) {
            // console.log('empresas', to)
            next({ path: '/starting/crear-empresa' })
        } else if (
            empresas.length !== 0 &&
            autenticado &&
            to.fullPath === '/starting/crear-empresa'
        ) {
            next({ path: '/home/general/generales/empresas' })
        } else if (
            empresas.length === 0 &&
            autenticado &&
            to.fullPath === '/starting/crear-empresa'
        ) {
            next()
        } else {
            if (
                filteredSedes.length === 0 &&
                autenticado &&
                to.fullPath !== '/starting/crear-sede'
            ) {
                // console.log('bbb')
                next({ path: '/starting/crear-sede' })
            } else if (
                filteredSedes.length !== 0 &&
                autenticado &&
                to.fullPath === '/starting/crear-sede'
            ) {
                // console.log('ccc')
                // router.back()
                next({ path: '/home/general/generales/sedes' })
            } else if (
                filteredSedes.length === 0 &&
                autenticado &&
                to.fullPath === '/starting/crear-sede'
            ) {
                next()
            }
        }
        next()
    }
    const ROUTE_NAME = to.name
    if (ROUTE_NAME) {
        // Start the route progress bar.
        document.title = `ERP - ${ROUTE_NAME}`
    }
})

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

export default router
