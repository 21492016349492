const menuChild = (item, items, addDependency = false) => {
    let newItem = {}
    const { icono: icon, title, routing: name, id, hijo } = item
    let children = items.filter((i) => i.hijo === id)
    if (!addDependency) {
        children = children.filter((c) => c.dependencia === false)
    }

    if (children.length > 0) {
        children.forEach((i, index, currentArray) => {
            let menuItem = menuChild(i, items, addDependency)
            currentArray.splice(index, 1, menuItem)
        })
        newItem = {
            id: id.toString(),
            title,
            name,
            icon,
            hijo,
            children: children
        }
    } else {
        newItem = {
            id: id.toString(),
            title,
            name,
            icon,
            hijo
        }
    }
    return newItem
}

const getters = {
    getUserFullName: (state) => {
        const { userData } = state
        if (userData) {
            const { nombre1, apellido1 } = userData
            return `${nombre1} ${apellido1}`
        } else {
            return ''
        }
    },
    getUserData: (state) => state.userData,
    getMenu: (state) => {
        const items = [...state.menu]
        const menu = []
        items
            .filter((i) => i.header)
            .forEach((item) => {
                if (!item.header) {
                    let menuItem = menuChild(item, items)
                    menu.push(menuItem)
                } else {
                    menu.push({
                        icon: item.icono,
                        header: item.header,
                        title: item.title,
                        id: item.id
                    })
                    const children = items.filter((i) => i.hijo === item.id)
                    children.forEach((c) => {
                        const ch = menuChild(c, items)
                        menu.push(ch)
                    })
                }
            })
        return menu
    },
    getMenuComplete: (state) => {
        const items = JSON.parse(JSON.stringify(state.menu))
        const menu = []
        items
            .filter((i) => i.header)
            .forEach((item) => {
                if (!item.header) {
                    let menuItem = menuChild(item, items, true)
                    menu.push(menuItem)
                } else {
                    menu.push({
                        icon: item.icono,
                        header: item.header,
                        title: item.title,
                        id: item.id
                    })
                    const children = items.filter((i) => i.hijo === item.id)
                    children.forEach((c) => {
                        const ch = menuChild(c, items, true)
                        menu.push(ch)
                    })
                }
            })
        return menu
    },
    getAuditoriaJson: (state, u, n, getters) => {
        const { userData } = state
        return {
            usuario_id: userData?.id,
            ip: '',
            modulo_id: null,
            empresa_id: getters['empresas/getUltimaEmpresa']?.id || 0,
            sede_id: getters['sedes/getUltimaSede']?.id || 0,
            computer_name: ''
        }
    },
    getPermisos: (state, u, n, getters) => {
        let currentRole = {}
        const roles = state.userData.roles
        const ultimaSede = getters['sedes/getUltimaSede']?.id

        if (roles.length === 1 && roles[0].sede_id === 0) {
            currentRole = roles[0]
        } else {
            currentRole = roles.find((r) => r.sede_id === ultimaSede)
        }

        return {
            permisos_array: currentRole?.role?.permisos,
            permisos_authorities: currentRole?.role?.permisos.map(
                (p) => p.authority
            )
        }
    }
}

export default getters
