export const getItem = key => {
  return localStorage.getItem(`ERP_${key}`)
}

export const setItem = (key, value) => {
  const stringValue = typeof value === 'string' ? value : JSON.stringify(value)
  localStorage.setItem(`ERP_${key}`, stringValue)
}

export const removeItem = key => {
  localStorage.removeItem(`ERP_${key}`)
}
