import axios from 'axios'
import store from '@/store/Store.js'
import router from '@/router/Router'
import { removeItem } from '@/utils/localStorage'

const { VUE_APP_API_URL, VUE_APP_REPORT_URL } = process.env

const object_services = [
    {
        url: VUE_APP_API_URL,
        instance_name: 'API'
    },
    {
        url: VUE_APP_REPORT_URL,
        instance_name: 'REPORTER'
    }
]

// const env = 'prod'

// const env = 'development'

// const object_services = [
//     {
//         url:
//             env === 'development'
//                 ? 'https://dev.softbusinesssr.com'
//                 : 'https://erp.softbusiness.com.co/backend',
//         path: env === 'development' ? '/8889/api/erp/' : '/api/erp',
//         instance_name: 'API'
//     },
//     {
//         url:
//             env === 'development'
//                 ? 'https://devreport.softbusiness.com.co'
//                 : 'https://erp.softbusiness.com.co',
//         path: env === 'development' ? '/erp/api' : '/reporter/api',
//         instance_name: 'REPORTER'
//     },
//     {
//         url: 'https://dev.softbusinesssr.com.co',
//         path: '/8889/api/websocket/',
//         instance_name: 'socket'
//     }
// ]

// console.log(object_services)

const requestInterceptorResponse = (config) => {
    return config
}

const requestInterceptorError = (error) => {
    return Promise.reject(error)
}

const responseInterceptorResponse = (response) => {
    return response
}

const responseInterceptorError = (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            // removeItem('TOKEN')
            // router.push('/auth/login').catch((error) => error)
            // const token = getItem('TOKEN')
            // if (token) {
            store.dispatch('usuario/logOut')
            // }
            return Promise.reject({
                code: 'USER_UNAUTHORIZED',
                message: 'Su sesión ha expirado, por favor inicie nuevamente'
            })
        }

        if (error.response.status === 403) {
            removeItem('TOKEN')
            router.push('/auth/login').catch((error) => error)
            // const token = getItem('TOKEN')
            // if (token) {
            store.dispatch('usuario/logOut')
            // }
            return Promise.reject({
                code: 'USER_UNAUTHORIZED',
                message:
                    'Su sesión ha expirado, o ya se encuentra logueado en otro navegador, por favor vuelva a iniciar sesión'
            })
        }
    }
    return Promise.reject(error)
}

const createInstance = ({ url /*, path*/ }) => {
    // const instance = axios.create({
    //     baseURL: `${url}${path}`,
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // })

    const instance = axios.create({
        baseURL: `${url}`,
        headers: {
            'Content-Type': 'application/json'
        }
    })

    instance.interceptors.request.use(
        requestInterceptorResponse,
        requestInterceptorError
    )
    instance.interceptors.response.use(
        responseInterceptorResponse,
        responseInterceptorError
    )

    return instance
}

let services = {}

object_services.forEach((service) => {
    const { instance_name } = service
    const instance = createInstance(service)
    services = { ...services, [instance_name]: instance }
})

export { services }
