import { diff } from 'deep-object-diff'

const numParaCalcular = [
    3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71
]

export const getDV = (nit) => {
    const ultimoNum = nit.length - 1
    let sumaTotal = 0

    for (let i = 0; i < nit.length; i++) {
        sumaTotal += parseInt(nit[i]) * numParaCalcular[ultimoNum - i]
    }

    const res = Math.round(((sumaTotal / 11) % 1) * 11)

    return res === 0 ? '0' : res === 1 ? '1' : `${11 - res}`
}

const pdfSignature = 'data:application/pdf' // 'JVBERi0'
const excelSignature =
    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // 'UEsDBBQABgAIAAAAIQ'
const htmlSignature = 'data:text/html' // 'PCFET0NUWVBFIGh0bWw'

export const getFileMetaData = (base64) => {
    let fileData,
        ext = ''
    if (base64.indexOf(excelSignature) === 0) {
        // fileData = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`
        ext = 'xlsx'
    } else if (base64.indexOf(pdfSignature) === 0) {
        // fileData = `data:application/pdf;base64,${base64}`
        ext = 'pdf'
    } else if (base64.indexOf(htmlSignature) === 0) {
        // fileData = `data:text/html;base64,${base64}`
        ext = 'html'
    }

    fileData = base64

    return { fileData, ext }
}

export const firstToUpperString = (moduleName) => {
    const firstToUpper = moduleName.charAt(0).toUpperCase()

    const restString = moduleName.substring(1, moduleName.length)

    const text = `${firstToUpper}${restString}`

    return text
}

export const currencyFormat = (value) => {
    const formatValue = typeof value === 'number' ? value.toString() : value
    return `$ ${formatValue.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.')}`
}

const handlerListenCommands = (
    { save, addRegister, back, openItemsTable },
    { metaKey, shiftKey, key, ctrlKey, altKey, code }
) => {
    // const { metaKey, shiftKey, key, ctrlKey, altKey, code } = e
    const saveRegister =
        (metaKey || ctrlKey) && shiftKey && key.toLowerCase() === 's'
    const newRegister = altKey && code.toLowerCase() === 'keya'
    const esc = key.toLowerCase() === 'escape'
    const searchItems = (metaKey || ctrlKey) && key.toLowerCase() === 'b'

    if (saveRegister) {
        return save && save()
    }

    if (newRegister) {
        return addRegister && addRegister()
    }

    if (esc) {
        return back && back()
    }

    if (searchItems) {
        return openItemsTable && openItemsTable()
    }
}

export const removeListenerCommands = ({ $el, ...rest }) => {
    $el.removeEventListener('keydown', (e) => handlerListenCommands(rest, e))
}

export const addListenerCommands = ({ $el, ...rest }) => {
    $el.addEventListener('keydown', (e) => handlerListenCommands(rest, e))
}

export const goToNextField = (FIELD_ID) => {
    setTimeout(() => {
        const ELEMENT = document.getElementById(FIELD_ID)

        ELEMENT.focus()
    }, 50)
}

export const onFocused = (evt) => {
    evt.target.select()
}

export const compareObjects = (object1, object2) => {
    // console.log(object1, object2, mo)
    const objectComparison = diff(object1, object2)
    const isSameObject = Object.keys(objectComparison).length === 0
    // console.log(objectComparison)

    return isSameObject
}

export const randomPassword = () => {
    return Math.random().toString(36).slice(-8)
}

export const uniqueArryObject = (arr) => {
    const uniqueIds = []

    const unique = arr.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.id)

        if (!isDuplicate) {
            uniqueIds.push(element.id)

            return true
        }

        return false
    })

    return unique
}

export const validateHour = (timeStr) => {
    var valid =
        timeStr.search(/^\d{2}:\d{2}:\d{2}$/) != -1 &&
        timeStr.substr(0, 2) >= 0 &&
        timeStr.substr(0, 2) <= 24 &&
        timeStr.substr(3, 2) >= 0 &&
        timeStr.substr(3, 2) <= 59 &&
        timeStr.substr(6, 2) >= 0 &&
        timeStr.substr(6, 2) <= 59

    return valid
}

export const findObjectInArray = (object, array) => {
    const obj = array.find((obj) => obj.id === object.id)
    return obj !== undefined
}

export const getComposedSearch = (search) => {
    const composedSerch = search.split('%')
    const composedSearchFilter = composedSerch.filter((w) => w.trim() !== '')

    if (composedSearchFilter.length === 0) {
        return ['']
    } else {
        return composedSearchFilter
    }
}

export function getBase64File(file, mimeType = 'data:application/zip;base64,') {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () => {
            resolve(reader.result.replace(mimeType, ''))
        }

        reader.onerror = reject

        reader.readAsDataURL(file)
    })
}

export function downloadFile({
    fileData,
    name,
    ext,
    mimeType = 'data:application/zip;base64,'
}) {
    const element = document.createElement('a')
    element.setAttribute('href', `${mimeType}${fileData}`)
    element.setAttribute('download', `${name}.${ext}`)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
}

export const arrayErrorsToHtmlList = (errors) => {
    const htmlErrors = errors
        .map((error) => {
            const htmlError = `<p>${error}</p>`
            return htmlError
        })
        .join('</br>')

    return htmlErrors
}

export const openNewHtmlContentWindow = (
    htmlBody,
    title = 'Errores de la importación'
) => {
    let child = window.open(
        'https://erp.softbusiness.com.co/error-log',
        '_blank'
        // 'width=500,height=300,resizable=yes,location=no,scrollbars=yes,toolbar=no'
    )

    try {
        child.document.write(`<html>
      <head>
        <title>${title}</title>
      </head>
      <body>
        ${htmlBody}
      </body>
      <style>
        p {
          font-size: 12px
        }
      </style>
    </html>
   `)
        child.document.close()
    } catch (e) {
        console.log(e.message)
    }
}

export const onUploadProgress = (progressEvent) => {
    const progress = parseFloat(
        (progressEvent.loaded / progressEvent.total).toFixed(2)
    )
    const percentage = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
    )

    return { progress, percentage }
}

export const emailRule = (v) =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        v
    )

export function getLabelTercero(tercero) {
    const {
        tipo_documento_id,
        nombre1,
        nombre2,
        apellido1,
        apellido2,
        razon_social
    } = tercero

    let label = ''

    if (tipo_documento_id == 13) {
        label = `${nombre1}${nombre2 && ` ${nombre2}`} ${apellido1}${
            apellido2 && ` ${apellido2}`
        }`
    } else {
        label = `${razon_social}`
    }

    return label
}
