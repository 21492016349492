const mutations = {
    setUserData: (state, userData) => {
        const unusedProperties = ['created_at', 'updated_at', 'password']
        if (userData) {
            unusedProperties.forEach((key) => {
                delete userData[key]
            })
        }

        state.userData = userData
    },
    setMenu: (state, menu) => {
        state.menu = menu
    }
}

export default mutations
