import pkg from 'crypto-js'
const { enc, AES, mode: _mode, pad } = pkg

const KEY = enc.Utf8.parse('o9QJavxa4jb5r4542iVq5ApFKXvzR69A')
const IV = enc.Utf8.parse('gFkS9ALNDOnhdRKN')

export const encrypt = (data) => {
    const encrypted = AES.encrypt(data ? data : '', KEY, {
        iv: IV,
        mode: _mode.CBC,
        padding: pad.Pkcs7
    })
    return encrypted.toString()
}

export const decrypt = (data) => {
    const encrypted = AES.decrypt(data ? data : '', KEY, {
        iv: IV,
        mode: _mode.CBC,
        padding: pad.Pkcs7
    })
    return encrypted.toString(enc.Utf8)
}
