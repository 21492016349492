import { removeItem, setItem } from '@/utils/localStorage'
import router from '@/router/Router'
import services from '@/boot/axios'
import { assignRoutes } from '../../Store'

const { API } = services

const actions = {
    login: ({ commit, getters }, sessionData) => {
        const ultimaSede = sessionData.usuario.ultima_sede
            ? Object.keys(sessionData.usuario.ultima_sede).length > 0
                ? JSON.parse(JSON.stringify(sessionData.usuario.ultima_sede))
                : sessionData.usuario.sedes[0]
            : null

        const ultimaEmpresa = sessionData.usuario.ultima_empresa
            ? Object.keys(sessionData.usuario.ultima_empresa).length > 0
                ? JSON.parse(JSON.stringify(sessionData.usuario.ultima_empresa))
                : sessionData.usuario.empresas.find(
                      (e) => e.id === ultimaSede.empresa_id
                  )
            : null

        const roles = JSON.parse(JSON.stringify(sessionData.usuario.roles))

        let role = {}
        if (roles.length === 1 && roles[0].sede_id === 0) {
            role = roles[0].role
        } else {
            const retrieveRole = roles.find((r) => r.sede_id === ultimaSede.id)
            role = retrieveRole.role
        }
        /* 
            Si es admin, entonces el rol siempre será igual,
            sino que busque la sede por selec ultima empresa
        */
        setItem('TOKEN', sessionData.token)
        commit('setUserData', sessionData.usuario)
        commit('setMenu', role.menus)
        commit('empresas/setEmpresas', sessionData.usuario.empresas || [], {
            root: true
        })
        commit('sedes/setSedes', sessionData.usuario.sedes || [], {
            root: true
        })
        commit('empresas/setUltimaEmpresa', ultimaEmpresa, {
            root: true
        })
        commit('sedes/setUltimaSede', ultimaSede, {
            root: true
        })
        // console.log(getters)
        assignRoutes(getters.getMenu)
        setTimeout(() => {
            router.push('/home/dashboard').catch((error) => error)
        }, 1000)
    },
    fullLogOut: ({ getters, rootGetters, dispatch }) => {
        API.POST({
            url: 'auth/logout',
            data: {
                p_datajson: {},
                p_opc: 'LOGOUT',
                p_auditoriajson: {
                    usuario_id: getters.getUserData?.id || null,
                    empresa_id:
                        rootGetters['empresas/getUltimaEmpresa']?.id || null
                }
            }
        })
            .then(() => {
                dispatch('logOut')
            })
            .catch((error) => {
                const { message } = error
                this.$bvModal
                    .msgBoxOk(message || 'Ha ocurrido un error!', {
                        title: 'Error!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'error',
                        okTitle: 'Aceptar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'error',
                        headerClass: 'modal-header'
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
    },
    logOut: ({ commit }) => {
        removeItem('TOKEN')
        commit('setUserData', null)
        commit('empresas/setEmpresas', [], { root: true })
        commit('sedes/setSedes', [], { root: true })
        commit('empresas/setUltimaEmpresa', null, { root: true })
        commit('sedes/setUltimaSede', null, { root: true })
        // console.log('aaaaaa')
        router.push('/auth/login').catch((error) => error)
    }
}

export default actions
